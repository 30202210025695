//TODO will need rework
import moment from 'moment-timezone'

export function singleValueColorSwatch(value: number, min = -1, max = 1, median = 0): string {
	if (value || value === 0) {
		if (value <= min) {
			return 'error--text'
		} else if (value >= max) {
			return 'success--text'
		} else if (value === median) {
			return 'orange--text text--darken-2'
		} else {
			return ''
		}
	} else {
		return ''
	}
}

//TODO will need rework
export function rangeColorSwatch(
	value: number,
	success?: Array<Array<number>>,
	warning?: Array<Array<number>>,
): string {
	if (value || value === 0) {
		const values: {
			success: Array<number>
			warning: Array<number>
		} = {
			success: [],
			warning: [],
		}

		if (success) {
			values.success = success.filter((val) => {
				if (value >= val[0] && value <= val[1]) {
					return val
				}
			})[0]
		}

		if (warning) {
			values.warning = warning.filter((val) => {
				if (value >= val[0] && value <= val[1]) {
					return val
				}
			})[0]
		}

		if (values.success) {
			if (value >= values.success[0] && value <= values.success[1]) {
				return 'success--text'
			} else {
				return 'error--text'
			}
		} else if (values.warning) {
			if (value >= values.warning[0] && value <= values.warning[1]) {
				return 'orange--text text--darken-2'
			} else {
				return 'error--text'
			}
		} else {
			return 'error--text'
		}
	} else {
		return ''
	}
}

export function dateColorSwatch(value: Date, success: Date, warning?: Date, featureLock = false): string {
	let dateNow = null

	if (featureLock) {
		const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

		dateNow = moment(new Date(date).toISOString()).format().substr(0, 19)
	}

	if (value) {
		if (featureLock && dateNow && new Date(value) > new Date(dateNow)) {
			return 'success--text'
		} else if (new Date(value) >= new Date(success)) {
			return 'success--text'
		} else if (warning && new Date(value) >= new Date(warning)) {
			return 'orange--text text--darken-2'
		} else {
			return 'error--text'
		}
	} else {
		return ''
	}
}
