import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'

export interface ISnackbar {
	active: boolean
	color: string
	message: string
	//timeout: number
}

enum snackbarMutation {
	SET_SNACKBAR_ACTIVE = 'setSnackbarActive',
	SET_SNACKBAR_COLOR = 'setSnackbarColor',
	SET_SNACKBAR_MESSAGE = 'setSnackbarMessage',
	//SET_SNACKBAR_TIMEOUT = 'setSnackbarTimeout',
}

const state: ISnackbar = {
	active: false,
	color: '',
	message: '',
	//timeout: 3000,
}

const getters: GetterTree<ISnackbar, IRootState> = {
	getSnackbar(state): ISnackbar {
		return state
	},
}

const mutations: MutationTree<ISnackbar> = {
	[snackbarMutation.SET_SNACKBAR_ACTIVE](state, payload) {
		state.active = payload
	},
	[snackbarMutation.SET_SNACKBAR_COLOR](state, payload) {
		state.color = payload
	},
	[snackbarMutation.SET_SNACKBAR_MESSAGE](state, payload) {
		state.message = payload
	},
	// [snackbarMutation.SET_SNACKBAR_TIMEOUT](state, payload) {
	// 	state.timeout = payload
	// },
}

const actions: ActionTree<ISnackbar, IRootState> = {
	setSnackbar({ commit }, payload: { message: string; color?: string /*timeout?: number*/ }): void {
		commit(snackbarMutation.SET_SNACKBAR_ACTIVE, true)
		commit(snackbarMutation.SET_SNACKBAR_MESSAGE, payload.message)

		if (payload.color) {
			commit(snackbarMutation.SET_SNACKBAR_COLOR, payload.color)
		}

		// if (payload.timeout) {
		// 	commit(snackbarMutation.SET_SNACKBAR_TIMEOUT, payload.timeout)
		// }
	},
	disableSnackbar({ commit }) {
		commit(snackbarMutation.SET_SNACKBAR_ACTIVE, false)
		commit(snackbarMutation.SET_SNACKBAR_MESSAGE, '')
		commit(snackbarMutation.SET_SNACKBAR_COLOR, '')
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
