// eslint-disable-next-line @typescript-eslint/ban-types
import moment from 'moment'

export function hasOwnProperty<X extends object, Y extends PropertyKey>(
	obj: X,
	prop: Y,
): obj is X & Record<Y, unknown> {
	// eslint-disable-next-line no-prototype-builtins
	return obj.hasOwnProperty(prop)
}

export function formatDate(date: Date, format: string): string | null {
	if (date) {
		return moment(date).format(format)
	} else {
		return null
	}
}

export function formatCurrencyWithSpaces(value: number): string {
	const splits = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

	return splits
}
