//TODO check on autocomplete && select inputs
export function requiredRule(message = 'This field is required'): (value: any) => true | string {
	return (value: any) => !!value || message
}

export function maxLength(length: number, message?: string): (value: any) => true | string {
	return (value: any) => {
		if (value === null || value === undefined || value === '') {
			return true
		} else {
			if (value.length <= length) {
				return true
			} else {
				if (!message) {
					return `Maximum length is ${length} characters`
				} else {
					return message?.replace('%d', String(length))
				}
			}
		}
	}
}

export function minLength(length: number, message?: string): (value: any) => true | string {
	return (value: any) => {
		if (value === null || value === undefined || value === '') {
			return true
		} else {
			if (value.length >= length) {
				return true
			} else {
				if (!message) {
					return `Minimum length is ${length} characters`
				} else {
					return message?.replace('%d', String(length))
				}
			}
		}
	}
}

export function range(range: [number, number], message?: string): (value: any) => true | string {
	return (value: any) => {
		if (value === null || value === undefined || value === '') {
			return true
		} else {
			if (value >= range[0] && value <= range[1]) {
				return true
			} else {
				if (!message) {
					return `Value must be between ${range[0]} and ${range[1]}`
				} else {
					return message.replace('%d', String(range[0])).replace('%d', String(range[1]))
				}
			}
		}
	}
}

//TODO need better solution
export function equal(object: any, propertyPath: string, message: string): (value: any) => true | string {
	return (value: any) => {
		let compare = object
		const props = propertyPath.split('.')

		props.forEach((prop) => {
			compare = compare[prop]
		})

		if (compare === value) {
			return true
		} else {
			return message
		}
	}
}

//Dirty solution
export function api(message: string) {}
