











import Vue from 'vue'

export default Vue.extend({
	name: 'SnackBar',
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		color: {
			type: String,
			required: false,
			default: () => 'grey darken-4',
		},
		timeout: {
			type: Number,
			required: false,
			default: () => 4000,
		},
		message: {
			type: String,
			required: true,
		},
	},
	watch: {
		value(val) {
			console.log(val)
			if (val === false) {
				this.$store.dispatch('snackbar/disableSnackbar')
			}
		},
	},
})
