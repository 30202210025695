import { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			visibility: true,
			icon: 'fa-home',
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
		meta: {
			visibility: false,
		},
	},
	{
		path: '/machines',
		name: 'Machines',
		component: () => import('@/views/Machines/Machines.vue'),
		meta: {
			visibility: true,
			icon: 'fa-gamepad',
		},
	},
	{
		path: '/machines/detail/:id',
		name: 'MachinesDetail',
		component: () => import('@/views/Machines/MachineDetail.vue'),
		meta: {
			visibility: false,
		},
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import('@/views/Profile.vue'),
		meta: {
			visibility: false,
		},
	},
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		component: () => import('@/views/error/Error404.vue'),
		meta: {
			visibility: false,
		},
	},
	{
		path: '/:pathMatch(.*)*',
		name: '500',
		component: () => import('@/views/error/Error500.vue'),
		meta: {
			visibility: false,
		},
	},
	{
		path: '*',
		redirect: {
			name: '404',
		},
		meta: {
			visibility: false,
		},
	},
]

export default routes
