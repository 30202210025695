










































































































import Vue from 'vue'
import ColorSwatches from '@/mixins/colorSwatches'
import apiClient from '@/services/danaApi'
import { ISummary } from '@/types/machines'
import moment from 'moment-timezone'
import { formatCurrencyWithSpaces } from '@/utils/helpers'

export default Vue.extend({
	name: 'Home',
	mixins: [ColorSwatches],
	data() {
		return {
			summary: {} as ISummary,
			summaryLoading: false,
			machinesCount: null,
			machinesLoading: false,
			currency: process.env.VUE_APP_CURRENCY as string,
			customerName: process.env.VUE_APP_CUSTOMER_NAME,
			timezone: process.env.VUE_APP_TIMEZONE as string,
		}
	},
	methods: {
		formatCurrency: formatCurrencyWithSpaces,
		async getStats() {
			let sinceDate = (): string => {
				const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

				return moment(new Date(new Date(date).setMonth(date.getMonth() - 1)).toISOString())
					.format()
					.substr(0, 10)
			}

			let toDate = (): string => {
				const date = new Date(moment(Date.now()).tz(process.env.VUE_APP_TIMEZONE).format())

				return moment(date.toISOString()).format().substr(0, 10)
			}

			this.summaryLoading = true

			await apiClient
				.get('/stats/summary', {
					params: {
						since_date: sinceDate(),
						to_date: toDate(),
					},
				})
				.then((response) => {
					this.summaryLoading = false
					this.summary = response.data.result
				})
		},
		async getMachines() {
			this.machinesLoading = true

			await apiClient.get('/info/machines').then((response) => {
				this.machinesLoading = false
				this.machinesCount = response.data.result.count
			})
		},
	},
	created() {
		this.getStats()
		this.getMachines()
	},
})
