import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import router from '@/router'
import authService from '@/services/authService'

//TODO bug when env is not provided who application have runtime errors
const DANA_URL = process.env.VUE_APP_DANA_API

//TODO temporary solution for authentication
const apiClient: AxiosInstance = axios.create({
	baseURL: DANA_URL,
	headers: {
		accept: 'application/json',
		'Content-type': 'application/json',
	},
	timeout: 5000,
})

apiClient.interceptors.request.use((config: AxiosRequestConfig) => {
	const token = authService.getToken()

	if (token) {
		config.headers = {
			...config.headers,
			authorization: token,
		}
	}

	return config
})

// apiClient.interceptors.request.use((request) => {
// 	console.log('Starting Request', JSON.stringify(request, null, 2))
// 	return request
// })

// apiClient.interceptors.response.use((response) => {
// 	console.log('Response:', JSON.stringify(response, null, 2))
// 	return response
// })
const debug = process.env.VUE_APP_DEBUG

if (debug !== 'true') {
	//TODO this is temporary error handling
	apiClient.interceptors.response.use(undefined, (error) => {
		let path: undefined | string = undefined

		if (!error.response) {
			path = '500'
		}

		if (error.code === 'ECONNABORTED') {
			path = '404'
		}

		if (error.response) {
			switch (error.response.status) {
				case 401:
					authService.destroyToken()
					path = 'Login'
					break
				case 403:
					path = '404'
					break
				case 404:
					path = '404'
					break
				case 424:
					path = '500'
					break
				case 500:
					path = '500'
					break
			}
		}

		//TODO handle callback some how?!
		//TODO pathMatch seems to be undefined
		if (path) {
			router
				.push({
					name: path,
					//@ts-ignore
					params: { pathMatch: router.app.$route.path.substring(1).split('/') },
					query: router.app.$route.query,
				})
				.catch(() => {})
		}

		return Promise.reject(error)
	})
}

export default apiClient
