import schindlerApi from '@/services/schindlerApi'
import qs from 'qs'
import jwtDecode from 'jwt-decode'
import { IJwtToken, IUserData } from '@/types/user'

//TODO needs refactor
class AuthService {
	async authenticate(username: string, password: string) {
		const data = {
			username: username,
			password: password,
		}

		return await schindlerApi
			.post('/auth/token', qs.stringify(data), {
				headers: { 'content-type': 'application/x-www-form-urlencoded' },
			})
			.then((response) => {
				if (response.data.access_token) {
					localStorage.setItem('user', JSON.stringify(response.data.access_token))
				}

				return response
			})
	}
	logout(): void {
		this.destroyToken()
	}
	getToken(): string {
		const token = localStorage.getItem('user')

		let userToken = ''

		if (token) {
			userToken = JSON.parse(token)
		}

		return userToken
	}
	destroyToken(): void {
		if (localStorage.getItem('user')) {
			localStorage.removeItem('user')
		}
	}
	getDecodeToken(): IJwtToken {
		const token = this.getToken()

		return jwtDecode<IJwtToken>(token)
	}
	getUserData(): IUserData {
		const token = this.getDecodeToken()

		return JSON.parse(token.user)
	}
}

export default new AuthService()
